import { Box, Button } from "@mui/material";
import React from "react";
import "./Portfolio.css";
import Dandelion from "../../../assets/Dandelion.webp";

const Portfolio = () => {
  return (
    <React.Fragment>
      <Box className="Portfolio">
        <Box>
          <Box>
            <Box></Box>
            <span>my personal</span>
            <span>portfolio</span>
            <q>
              A creative person steps into the world and It adds to the beauty
              of the world. A song here Another painting there, he makes the
              dance of the world more harmonious with his existence.
            </q>
            <span>
              Human ideas and projects become reality when they are set in
              motion.
            </span>
            <span>
              I wrote many of my ideas on paper, but unfortunately I have lost
              them. These were a small part of the ideas I had and I continue to
              create ideas
            </span>
            <Button variant="contained">
              <span>Show More</span>
            </Button>
          </Box>
        </Box>
        <Box>
          <img src={Dandelion} alt="" />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Portfolio;
