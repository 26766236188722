import { Box, Grid, Button } from "@mui/material";
import React from "react";
import "./About.css";
import Deer from "../../../assets/Deer.webp";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Progress from "../../progress/Progress";
import Bike from "../../../assets/Bike.webp";

const About = () => {
  return (
    <React.Fragment>
      <Box className="ScrollDown">
        scroll down <span className="fas fa-arrow-right"></span>
      </Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2 }}
        className="About"
      >
        <Grid item xs={12} sm={6}>
          <img src={Deer} alt="" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Box></Box>
            <Box>About Me</Box>
            <Box>
              I have a curious look. Colors are very important to me. I love all
              people and I believe in human dignity. Doing projects accurately
              and beautifully so that everything works properly is very
              important to me.I love learning and practicing.
              <br />
              <br />
              In addition, I have a team of professional programmers who can
              turn your ideas into reality.
            </Box>
            <Box className="Buttons">
              <Box>
                <Button variant="contained">HIRE ME</Button>
              </Box>
              <Box>
                <Button variant="contained">
                  <ArrowRightAltIcon />
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Box></Box>
            <span>My Skills</span>
          </Box>
          <Box>
            <Progress score="45" title="Web Designer" color="success" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Progress score="50" title="Cryptocurrency" color="inherit" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Progress score="80" title="Web Developer" color="secondary" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Progress score="40" title="FullStack" color="error" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={Bike} alt="" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Box>About</Box>
            <Box>Details of My Skills</Box>
            <Box></Box>
            <Box></Box>
            <Box></Box>
            <Box>
              I have sufficient expertise in most of the following skills. But
              that doesn't mean I don't need to keep learning. Rather, I am
              being updated all the time. As you can see, I do not have enough
              experience in some skills and I am looking to acquire skills in
              that field.
            </Box>
            <Box>
              <Progress score="90" title="HTML-CSS" color="warning" />
              <Progress score="80" title="JavaScript" color="warning" />
              <Progress score="75" title="ReactJS" color="warning" />
              <Progress score="80" title="Material UI5" color="warning" />
              <Progress score="45" title="UI-UX Designe" color="warning" />
              <Progress score="95" title="Java Core" color="warning" />
              <Progress score="0" title="MongoDB" color="warning" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default About;
