import { Box } from "@mui/material";
import React from "react";
import "./Service.css";
import TerminalIcon from "@mui/icons-material/Terminal";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BrightnessMediumRoundedIcon from "@mui/icons-material/BrightnessMediumRounded";
import ReactLogo from "../../../assets/react.png";

const Service = () => {
  const [HTML, setHTML] = React.useState(false);
  const [REACT, setREACT] = React.useState(false);
  const [WEB, setWEB] = React.useState(false);
  const [PROJECT, setPROJECT] = React.useState(false);
  const [APP, setAPP] = React.useState(false);
  const myStyle = {
    color: "var(--green)",
    backgroundColor: "#FFF",
    transition: "all 0.7s ease-in-out",
  };

  return (
    <React.Fragment>
      <Box className="Service">
        <Box>Service</Box>
        <Box>Service Provide</Box>
        <Box></Box>
        <Box></Box>
        <Box>
          <Box
            className="Services"
            onMouseOver={() => setHTML(true)}
            onMouseOut={() => setHTML(false)}
          >
            <TerminalIcon style={HTML ? myStyle : null} />
            <h3>HTML-CSS Training</h3>
            <Box>
              I am able to teach HTML and CSS topics in the best way from the
              basic topics of web design.
            </Box>
          </Box>
          <Box
            className="Services"
            onMouseOver={() => setREACT(true)}
            onMouseOut={() => setREACT(false)}
          >
            <img src={ReactLogo} alt="" />
            <h3>React Training</h3>
            <Box>
              I can teach all basic, advanced React concepts in the simplest
              terms. Hooks, Router and more...
            </Box>
          </Box>
          <Box
            className="Services"
            onMouseOver={() => setWEB(true)}
            onMouseOut={() => setWEB(false)}
          >
            <ImportantDevicesIcon style={WEB ? myStyle : null} />
            <h3>Web Design</h3>
            <Box>
              Carrying out all web design projects - Client side and Server side
              - as you want
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            className="Services"
            onMouseOver={() => setPROJECT(true)}
            onMouseOut={() => setPROJECT(false)}
          >
            <AccountTreeIcon style={PROJECT ? myStyle : null} />
            <h3>Students Projects</h3>
            <Box>
              I can teach all basic, advanced React concepts in the simplest
              terms. Hooks, Router and more...
            </Box>
          </Box>
          <Box
            className="Services"
            onMouseOver={() => setAPP(true)}
            onMouseOut={() => setAPP(false)}
          >
            <BrightnessMediumRoundedIcon style={APP ? myStyle : null} />
            <h3>App Design</h3>
            <Box>
              I can teach all basic, advanced React concepts in the simplest
              terms. Hooks, Router and more...
            </Box>
          </Box>
          <Box className="Servicess"></Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Service;
