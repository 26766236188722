import { Box } from "@mui/material";
import React from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import "./Links.css";
import "../animated/AUL.css";

const Links = (propse) => {
  const [home, setHome] = React.useState("var(--appColor)");
  const [about, setAbout] = React.useState("var(--appColor)");
  const [service, setService] = React.useState("var(--appColor)");
  const [portfolio, setPortfolio] = React.useState("var(--appColor)");
  const [resume, setResume] = React.useState("var(--appColor)");
  const [contact, setContact] = React.useState("var(--appColor)");
  const location = useLocation();

  React.useEffect(() => {
    if (window.location.pathname === "/") {
      setHome("var(--red)");
    } else {
      setHome("var(--appColor)");
    }
    if (window.location.pathname === "/about") {
      setAbout("var(--red)");
    } else {
      setAbout("var(--appColor)");
    }
    if (window.location.pathname === "/service") {
      setService("var(--red)");
    } else {
      setService("var(--appColor)");
    }
    if (window.location.pathname === "/portfolio") {
      setPortfolio("var(--red)");
    } else {
      setPortfolio("var(--appColor)");
    }
    if (window.location.pathname === "/resume") {
      setResume("var(--red)");
    } else {
      setResume("var(--appColor)");
    }
    if (window.location.pathname === "/contact") {
      setContact("var(--red)");
    } else {
      setContact("var(--appColor)");
    }
  }, [location, propse]);
  const myStylesLinks = {
    animation: "SHOW2 0.5s ease-in-out 1s 1 forwards",
    display: "flex",
  };

  return (
    <React.Fragment>
      <Box className="Links" style={propse.LINKS ? myStylesLinks : null}>
        <Link to="/" style={{ color: home }} className="Links1">
          <span className={home === "var(--appColor)" ? "AUL1" : null}>
            home
          </span>
        </Link>
        <Link to="/about" style={{ color: about }} className="Links2">
          <span className={about === "var(--appColor)" ? "AUL2" : null}>
            about
          </span>
        </Link>
        <Link to="/service" style={{ color: service }} className="Links3">
          <span className={service === "var(--appColor)" ? "AUL3" : null}>
            service
          </span>
        </Link>
        <Link to="/portfolio" style={{ color: portfolio }} className="Links4">
          <span className={portfolio === "var(--appColor)" ? "AUL4" : null}>
            portfolio
          </span>
        </Link>
        <Link to="/resume" style={{ color: resume }} className="Links5">
          <span className={resume === "var(--appColor)" ? "AUL5" : null}>
            resume
          </span>
        </Link>
        <Link to="/contact" style={{ color: contact }} className="Links6">
          <span className={contact === "var(--appColor)" ? "AUL6" : null}>
            contact
          </span>
        </Link>
      </Box>
      <Outlet />
    </React.Fragment>
  );
};

export default Links;
