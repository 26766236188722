import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import "./Header.css";
import RM from "../../assets/RM.webp";
import "../animated/AUL.css";
import Links from "../links/Links";
import Language from "../../assets/Lang.webp";
import SortRoundedIcon from "@mui/icons-material/SortRounded";

const Header = () => {
  const [ENG, setENG] = useState(false);
  const [FAR, setFAR] = useState(true);
  const [TUR, setTUR] = useState(true);
  const [ShowModal, setShowModal] = useState(false);
  const ClickedLang = (event) => {
    let result = event.target.className.slice(0, 4);
    if (result === "AUL1") {
      setENG(true);
      setFAR(true);
      setTUR(false);
    }
    if (result === "AUL2") {
      setENG(true);
      setFAR(false);
      setTUR(true);
    }
    if (result === "AUL3") {
      setENG(false);
      setFAR(true);
      setTUR(true);
    }
  };
  const myRed = {
    color: "var(--red)",
  };
  const myGray = {
    color: "var(--appColor)",
  };
  const modalHandler = {
    animation: "SHOW 0.5s ease-in-out 0s 1 forwards",
  };
  const modalHandlerHide = {
    animation: "HIDE 0.5s ease-in-out 0s 1 forwards",
  };

  return (
    <React.Fragment>
      <Box
        className="Modals"
        style={ShowModal ? modalHandler : modalHandlerHide}
      >
        <Box></Box>
        <Box onClick={() => setShowModal(false)}></Box>
      </Box>
      <Box className="Header">
        <Box className="Header-Logo">
          <img src={RM} alt="" />
        </Box>
        <Box className="Header-Menu">
          <Links LINKS={ShowModal} />
        </Box>
        <Box className="Header-Lang">
          <Box>
            <Box
              className={TUR === true ? "AUL1" : "NNN1"}
              onClick={ClickedLang}
              style={TUR === false ? myRed : myGray}
            >
              TUR
            </Box>
            <Box
              className={FAR === true ? "AUL2" : "NNN2"}
              onClick={ClickedLang}
              style={FAR === false ? myRed : myGray}
            >
              FAR
            </Box>
            <Box
              className={ENG === true ? "AUL3" : "NNN3"}
              onClick={ClickedLang}
              style={ENG === false ? myRed : myGray}
            >
              ENG
            </Box>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
          >
            <img src={Language} alt="" />
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => setShowModal(true)}
          >
            <SortRoundedIcon />
          </IconButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Header;
