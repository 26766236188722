import { Box } from "@mui/material";
import React from "react";
import "./Resume.css";

const Resume = () => {
  const [change, setChange] = React.useState(0);
  const myStyle = {
    border: "1px solid var(--green)",
    color: "#FFF",
    backgroundColor: "var(--green)",
    transition: "all 0.3s ease-in-out",
  };
  const myStyleOUT = {
    transition: "all 0.3s ease-in-out",
  };

  return (
    <React.Fragment>
      <Box className="Resume">
        <Box>Experiance</Box>
        <Box>Work Experiance</Box>
        <Box>
          <Box></Box>
          <Box></Box>
        </Box>
        <Box className="Vertical">
          {/* Vertical */}
          <Box>
            {/************************************ WE1 **********************************/}
            <Box className="WorkExperiance WE1">
              <Box className="Left">
                <Box
                  style={change === 1 ? myStyle : myStyleOUT}
                  className="WorkExperianceLeft WEL1"
                >
                  The project lasted for 2 months, in this project, the server
                  needed to identity all the client connected to it ant the same
                  time, it calculated their CPU heat. The technology used in
                  this project was ServerSoket and I ussed the Java programming
                  language .
                </Box>
              </Box>
              <Box className="Center">
                <Box
                  onMouseOver={() => setChange(1)}
                  onMouseOut={() => setChange(0)}
                >
                  <em>01</em>
                </Box>
              </Box>
              <Box className="Right">
                <Box className="WorkExperianceRight WER1">
                  <strong>Java Programmer</strong>
                  <b>Dez Dam Project</b>
                  <label>Kuzestan - Andimeshk - Iran</label>
                </Box>
              </Box>
            </Box>
            {/************************************ WE2 **********************************/}
            <Box className="WorkExperiance WE2">
              <Box className="Left">
                <Box className="WorkExperianceRight WER2">
                  <strong>Frontend, Web Developer </strong>
                  <b>VANCOUVER, CANADA</b>
                  <label>July 2014 - July 2015</label>
                </Box>
              </Box>
              <Box className="Center">
                <Box
                  onMouseOver={() => setChange(2)}
                  onMouseOut={() => setChange(0)}
                >
                  <em>02</em>
                </Box>
              </Box>
              <Box className="Right">
                <Box
                  style={change === 2 ? myStyle : myStyleOUT}
                  className="WorkExperianceLeft  WEL2"
                >
                  I Developed a website called Baam Canada with CSS, HTML,
                  JavaScript, JQuery.
                </Box>
              </Box>
            </Box>
            {/************************************ WE3 **********************************/}
            <Box className="WorkExperiance WE3">
              <Box className="Left">
                <Box
                  style={change === 3 ? myStyle : myStyleOUT}
                  className="WorkExperianceLeft  WEL3"
                >
                  An electrical and electronics service company needed a website
                  to provide some information and attract customers. The
                  languages used in this project were HTML, CSS, Javascript,
                  JQuery.
                </Box>
              </Box>
              <Box className="Center">
                <Box
                  onMouseOver={() => setChange(3)}
                  onMouseOut={() => setChange(0)}
                >
                  <em>03</em>
                </Box>
              </Box>
              <Box className="Right">
                <Box className="WorkExperianceRight WER3">
                  <strong>Frontend, Web Developer </strong>
                  <b>KARAJ, IRAN</b>
                  <label>July 2014 - January 2015</label>
                </Box>
              </Box>
            </Box>
            {/************************************ WE4 **********************************/}
            <Box className="WorkExperiance WE4">
              <Box className="Left">
                <Box className="WorkExperianceRight WER4">
                  <strong>Frontend, Web Developer </strong>
                  <b>KARAJ, IRAN</b>
                  <label>January 2015 - January 2018</label>
                </Box>
              </Box>
              <Box className="Center">
                <Box
                  onMouseOver={() => setChange(4)}
                  onMouseOut={() => setChange(0)}
                >
                  <em>04</em>
                </Box>
              </Box>
              <Box className="Right">
                <Box
                  style={change === 4 ? myStyle : myStyleOUT}
                  className="WorkExperianceLeft  WEL4"
                >
                  Designing a website for an advertising company selling more
                  than 1000 types of advertising products and attracting
                  customers and signing advertising contracts on the Internet.
                  The language used in this project was PHP along with
                  JavaScript, CSS, HTML, and JQuery.
                </Box>
              </Box>
            </Box>
            {/************************************ WE5 **********************************/}
            <Box className="WorkExperiance WE5">
              <Box className="Left">
                <Box
                  style={change === 5 ? myStyle : myStyleOUT}
                  className="WorkExperianceLeft  WEL5"
                >
                  For 5 years, I was responsible for the public relations of ITH
                  Iran's hapkido style, and the developer of the
                  WWW.HapkidITH.ir website, later, the address and title of the
                  site were changed.
                </Box>
              </Box>
              <Box className="Center">
                <Box
                  onMouseOver={() => setChange(5)}
                  onMouseOut={() => setChange(0)}
                >
                  <em>05</em>
                </Box>
              </Box>
              <Box className="Right">
                <Box className="WorkExperianceRight WER5">
                  <strong>I.T.H Hapkido </strong>
                  <b>TEHRAN, IRAN</b>
                  <label>May 2015 - May 2020</label>
                </Box>
              </Box>
            </Box>
            {/************************************ WE6 **********************************/}
            <Box className="WorkExperiance WE6">
              <Box className="Left">
                <Box className="WorkExperianceRight WER6">
                  <strong>Frontend, Web Developer </strong>
                  <b>KARAJ, IRAN</b>
                  <label>June 2019 - May 2021</label>
                </Box>
              </Box>
              <Box className="Center">
                <Box
                  onMouseOver={() => setChange(6)}
                  onMouseOut={() => setChange(0)}
                >
                  <em>06</em>
                </Box>
              </Box>
              <Box className="Right">
                <Box
                  style={change === 6 ? myStyle : myStyleOUT}
                  className="WorkExperianceLeft  WEL6"
                >
                  As the head of the public relations department, I was
                  responsible for designing a website to attract members of the
                  BJ Alborz mountaineering group. I designed the group and site
                  logo .
                </Box>
              </Box>
            </Box>
            {/************************************ WE7 **********************************/}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Resume;
