import { Box, Button } from "@mui/material";
import React from "react";
import "./Contact.css";
import DandelionLarg from "../../../assets/DandelionLarg.webp";
import SocialMedia from "../../socialMedia/SocialMedia";
import Arrow from "../../../assets/arrow.png";

const Contact = () => {
  return (
    <React.Fragment>
      <Box className="Contact">
        <Box>
          <span>Have any Project in Mind ?</span>
          <br />
          <span>Just Say Hello!</span>
          <br />
          <Button variant="contained">
            <span>hire me</span>
            <i className="fas fa-long-arrow-alt-down"></i>
          </Button>
          <Box></Box>
          <Box></Box>
        </Box>
        <Box>
          <img src={DandelionLarg} alt="" />
        </Box>
        <Box className="ContactMiddle">
          <Box>
            <Box>
              Let's make something new, different and more meaningful or make
              thing more visual or Conceptual?
              <strong> Just Say Hello !</strong>
              <br />
              <br />
              <i className="fas fa-angle-right"></i> +90 (532) 847-30-76
              <br />
              <i className="fas fa-angle-right"></i> mountain.mr@proton.me
              <br />
              <i className="fas fa-angle-right"></i> Turkey, Mersin, Yenisehir,
              university Road, 3235 St., Erbas BL, 10th F, 40 U, postal Code :
              33110
              <Box className="SocialMediaContact">
                <SocialMedia CC="warning" />
              </Box>
            </Box>
          </Box>
          <Box>
            <img src={Arrow} alt="" width="70" height="6" />
          </Box>
          <Box>
            <form className="Email-Countainer">
              <input type="text" name="NAME" placeholder="Your Name" />
              <input
                type="text"
                name="EmailAddress"
                placeholder="Email Address"
              />
              <input
                type="text"
                name="Budget"
                placeholder="Your Budget (Optional)"
              />
              <textarea
                name="Description"
                placeholder="Project Description"
              ></textarea>
              <Button variant="contained" color="warning">
                <span>hire me</span>
              </Button>
            </form>
          </Box>
        </Box>
        <Box className="ContactFooter">
          <span>
            Copyright &#169; December-2021 Ramin Maghsoodi. All rights Reserved.
          </span>
          <br />
          <span>Created by Ramin Maghsoodi</span>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Contact;
