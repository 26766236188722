import { Box, Button } from "@mui/material";
import React from "react";
import "./Home.css";
import "../../animated/KF.css";
import Boat from "../../../assets/Boat.png";
import SocialMedia from "../../socialMedia/SocialMedia";

const Home = () => {
  return (
    <React.Fragment>
      <img src={Boat} alt="" className="Boat" />
      <Box className="Home">
        <Box className="IntroHome">
          <Box></Box>
          <Box>HELLO</Box>
          <Box>
            I'm <span>Ramin</span> Maghsoodi
          </Box>
          <Box>
            This is Ramin Maghsoodi visual designer, Web Developer, Creative
            Director located in Turkye, looking for works around the globe
          </Box>
          <Button variant="contained">
            <span>Download CV</span>
          </Button>
        </Box>
        <Box className="SocialMediaHome">
          <SocialMedia CC="red" />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Home;
