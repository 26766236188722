import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import "./SocialMedia.css";

const SocialMedia = (propse) => {
  const [ChangeColor, setChangeColor] = useState(2);
  const ChangeColorHandler = (event) => {
    setChangeColor(event);
  };

  const MS1 = {
    fontSize: "15px",
    transition: "all 0.3s",
  };
  const MS2 = {
    fontSize: "15px",
    color: "var(--" + propse.CC + ")",
    transition: "all 0.3s",
  };
  const MS3 = {
    fontSize: "15px",
    transition: "all 0.3s",
    color: "var(--" + propse.CC + ")",
  };

  return (
    <React.Fragment>
      <Box className="SocialMedia">
        <IconButton
          size="medume"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
          onMouseOver={() => ChangeColorHandler(1)}
          onMouseOut={() => ChangeColorHandler(2)}
        >
          <i
            className="fa fa-instagram instagram"
            style={ChangeColor === 1 ? MS2 : MS1}
          ></i>
        </IconButton>
        <IconButton
          size="medume"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
        >
          <i className="fa fa-dribbble dribbble" style={MS3}></i>
        </IconButton>
        <IconButton
          size="medume"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
          onMouseOver={() => ChangeColorHandler(3)}
          onMouseOut={() => ChangeColorHandler(2)}
        >
          <i
            className="fa fa-linkedin linkedin"
            style={ChangeColor === 3 ? MS2 : MS1}
          ></i>
        </IconButton>
        <IconButton
          size="medume"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
          onMouseOver={() => ChangeColorHandler(4)}
          onMouseOut={() => ChangeColorHandler(2)}
        >
          <i
            className="fa fa-youtube youtube"
            style={ChangeColor === 4 ? MS2 : MS1}
          ></i>
        </IconButton>
        <IconButton
          size="medume"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
          onMouseOver={() => ChangeColorHandler(5)}
          onMouseOut={() => ChangeColorHandler(2)}
        >
          <i
            className="fa fa-pinterest pinterest"
            style={ChangeColor === 5 ? MS2 : MS1}
          ></i>
        </IconButton>
        <IconButton
          size="medume"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
          onMouseOver={() => ChangeColorHandler(6)}
          onMouseOut={() => ChangeColorHandler(2)}
        >
          <i
            className="fa fa-twitter twitter"
            style={ChangeColor === 6 ? MS2 : MS1}
          ></i>
        </IconButton>
        <IconButton
          size="medume"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 1 }}
          onMouseOver={() => ChangeColorHandler(7)}
          onMouseOut={() => ChangeColorHandler(2)}
        >
          <i
            className="fa fa-github github"
            style={ChangeColor === 7 ? MS2 : MS1}
          ></i>
        </IconButton>
      </Box>
    </React.Fragment>
  );
};

export default SocialMedia;
