import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import "./Progress.css";

const Progress = (propse) => {
  const [progress, setProgress] = React.useState(0);
  let ML = 0;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = 1;
        return Math.min(oldProgress + diff, propse.score);
      });
    }, 50);
    return () => {
      clearInterval(timer);
    };
  }, [propse.score]);
  return (
    <React.Fragment>
      <Box className="LinearProgress">
        <Box>
          <Box>{propse.title}</Box>
          <Box>{progress}%</Box>
        </Box>
        <Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            color={propse.color}
            sx={{
              borderRadius: "2px",
              marginTop: "0.8rem",
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default React.memo(Progress);
